/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { NavLink } from 'react-router-dom';

// components
import Container from './Container';

// css
import './css/common.scss';

// images
import LOGO from '../../images/logo.png';

export default function Header() {
  return (
    <nav id="header" className="navbar navbar-expand-lg navbar-dark">
      <Container>
        <a className="navbar-brand" href="/">
          <img src={LOGO} className="gc-logo" alt="Gesch Consult" />
        </a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="/navbarColor03" aria-controls="navbarColor03" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarColor03">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <NavLink className="nav-link" exact to="/" activeClassName="active">
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/services" activeClassName="active">Services</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/projects" activeClassName="active">Projects</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/about" activeClassName="active">About Us</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/contact" activeClassName="active">Get in touch</NavLink>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link" href="/admin" activeClassName="active">Login</a>
            </li> */}
          </ul>
        </div>
      </Container>
    </nav>
  );
}
