import React from 'react';

// css
import './contact.scss';

import contactUrl from '../../images/social.jpeg';
import contactImage from '../../images/red_tel.png';

// components
import Section from '../common/Section';
import Container from '../common/Container';
import Row from '../common/Row';
import Col from '../common/Column';
import PageBanner from '../common/PageBanner';
import ContactCard from './ContactCard';
import ContactForm from './ContactForm';
import GeschMap from './GeschMap';

import { Headset, Mail, Location } from './Icons';

function Contact(props) {
  return (
    <div id="contact-page">
      <PageBanner
        title="Contact Us"
        subtitle="Reach out to us for any inquiries"
        bannerImageUrl={contactUrl}
      />
      <Section className="ptb--120">
        <Container>
          <Row>
            <Col className="col-lg-4 col-md-6 col-sm-6 col-12">
              <ContactCard
                title="Contact with Phone Number"
                p1="+256 787 007 754"
                p2="+256 393 206 360"
              >
                <Headset />
              </ContactCard>
            </Col>
            <Col className="col-lg-4 col-md-6 col-sm-6 col-12">
              <ContactCard
                title="Email Address"
                p1="info@geschconsult.com"
                p2="gesch@geschconsult.com"
              >
                <Mail />
              </ContactCard>

            </Col>
            <Col className="col-lg-4 col-md-6 col-sm-6 col-12">
              <ContactCard
                title="Location"
                p1="P.O.Box: 114729, Kampala"
                p2="Plot 855, Mawanda Road"
              >
                <Location />
              </ContactCard>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section className="light-bg ptb--120">
        <Container>
          <Row>
            <Col className="col-lg-6">
              <h2 className="title">
                Email Us.
              </h2>
              <p className="description">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto cupiditate aperiam neque.
              </p>
              <ContactForm />
            </Col>
            <Col className="col-lg-6 layout vertical center-justified">
              <div className="thumbnail layout horizontal center-justified">
                <img className="no-border" width="400" src={contactImage} alt="Contact Us" />
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section className="ptb--120">
        <Container>
          <Row>
            <Col>
              <div style={{
                height: '60vh',
                width: '100%',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)'
              }}>
                <GeschMap />
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </div>
  );
}

export default Contact;
